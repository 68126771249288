const DateTimeUtils =  {

    dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],

    getDayNoonTimestamp(d: Date) {
        d.setHours(12,0,0)
        d.setMilliseconds(0)
        return Math.floor(d.getTime() / 1000)
    },

    getMidnight(d: Date) {
        d.setHours(24,0,0)
        d.setMilliseconds(0)
        return d
    },

    getMidnightToMidnight(d: Date) {
        const start = new Date(d.getTime())
        start.setHours(0,0,0)
        start.setMilliseconds(0)
        const end = new Date(d.getTime())
        end.setHours(23,59,59)
        end.setMilliseconds(0)
        return { 
            'start': Math.floor(start.getTime() / 1000),
            'end': Math.floor(end.getTime() / 1000)
        }
    },

    getFirstDayOfMonth(d: Date) {
        const result = new Date( + d.getFullYear() + '-' + (d.getMonth()+1) + '-01')
        result.setHours(0)
        result.setMinutes(0)
        result.setSeconds(0)
        return result
    },

    getLastDayOfMonth(d: Date) {
        const result = new Date(d.getFullYear(), (d.getMonth()+1), 0)
        result.setHours(23)
        result.setMinutes(59)
        result.setSeconds(59)
        return result
    },

    getFirstDayOfLastMonth(d: Date) {
        const result = new Date( + d.getFullYear() + '-' + (d.getMonth()) + '-01')
        result.setHours(0)
        result.setMinutes(0)
        result.setSeconds(0)
        return result
    },    

    getLastDayOfLastMonth(d: Date) {
        const result = new Date(d.getFullYear(), (d.getMonth()), 0)
        result.setHours(23)
        result.setMinutes(59)
        result.setSeconds(59)
        return result
    },

    getFirstDayOfWeek(d: Date) {
        d = new Date(d)
        const day = d.getDay()
        d.setHours(0)
        d.setMinutes(0)
        d.setSeconds(0)
        const diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    },

    getLastDayOfWeek(d: Date) {
        d = new Date(d)
        const day = d.getDay()
        const diff = d.getDate() - day + (day == 0 ? -6:1) + 6; // adjust when day is sunday
        const result = new Date(d.setDate(diff));
        result.setHours(23)
        result.setMinutes(59)
        result.setSeconds(59)
        return result
    },

    getFirstDayOfQuarter(d: Date) {
        const result = new Date(d.getFullYear(), Math.floor((d.getMonth()+1) / 3) * 3, 1)
        result.setHours(0)
        result.setMinutes(0)
        result.setSeconds(0)
        return result
    },

    getLastDayOfQuarter(d: Date) {
        const result = new Date(d.getFullYear(), Math.floor((d.getMonth()+1) / 3) * 3 +3, 0)
        result.setHours(23)
        result.setMinutes(59)
        result.setSeconds(59)
        return result
    },

    getFirstDayOfLastQuarter(d: Date) {
        let year = d.getFullYear() 
        let month = Math.floor((d.getMonth()-3) / 3) * 3
        year = month < 0 ? year - 1 : year
        month = (month + 12) % 12
        const result = new Date(year, month, 1)
        result.setHours(0)
        result.setMinutes(0)
        result.setSeconds(0)
        return result
    },

    getFirstDayOfHalfYear(d: Date) {
        const month = d.getMonth() < 6 ? 0 : 6
        const result = new Date(d.getFullYear(), month, 1)
        result.setHours(0)
        result.setMinutes(0)
        result.setSeconds(0)
        return result
    },    

    getLastDayOfHalfYear(d: Date) {
        const month = d.getMonth() < 6 ? 6 : 12
        const result = new Date(d.getFullYear(), month, 0)
        result.setHours(23)
        result.setMinutes(59)
        result.setSeconds(59)
        return result
    },    

    getFirstDayOfLastHalfYear(d: Date) {
        let year = d.getFullYear() 
        let month = d.getMonth() -6
        month = month < 6 ? 0 : 6
        year = month < 0 ? year - 1 : year
        const result = new Date(year, month, 1)
        result.setHours(0)
        result.setMinutes(0)
        result.setSeconds(0)
        return result
    },  

    treatAsUTC(date: Date): any {
        const result = new Date(date);
        result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
        return result;
    },

    daysBetween(startDate: Date, endDate: Date) {
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        return (this.treatAsUTC(endDate) - this.treatAsUTC(startDate)) / millisecondsPerDay +1;
    },

    addDays(date: Date, days: number): Date {
        const d = new Date(date.valueOf());
        d.setDate(d.getDate() + days);
        return d;
    },

    getDatesFromTo(from: Date, to: Date) {
        from.setHours(0,0,0,0)
        const days = this.daysBetween(from, to) -1
        const dates = []
        for(let i=0; i < days; i++) {
            const d = DateTimeUtils.addDays(from, i)
            dates.push({
                'day': d.getDate(),
                'weekday': d.getDay(),
                'fullDate': d,
                'date': this.formatDate(d),
                'timestamp': + d,
                'dateNumber': this.getDateNumber(d),
                'index': i,
                'from': +d,
                'to': +d + 86400000
            })
        }
        return dates    
    },

    getTimesFromTo(from: number, to: number) {
        const times = [];
        const amount = to - from
        for(let i=0; i<=amount; i++) {
            const c = from + i
            times.push({
                index: i,
                time: c,
                label: (c < 10 ? '0' + c : c) + ':00',
                timestamp: c*60*60
            })
        }
        return times
    },

    getTimeOnly(d: Date) {
        const midnight = new Date(d.getTime())
        midnight.setHours(0,0,0)
        midnight.setMilliseconds(0)
        return Math.round((d.getTime() - midnight.getTime()) / 1000)
    },

    getDateOnly(d: Date) {
        const midnight = new Date(d.getTime())
        midnight.setHours(0,0,0)
        midnight.setMilliseconds(0)
        return midnight.getTime() / 1000
    },

    formatDate(d: Date | null): string {
        if (d) {
            return (d.getDate()<10 ? '0': '') + d.getDate() + '.' + (d.getMonth()+1 < 10 ? '0' : '') + (d.getMonth()+1) + '.' + d.getFullYear()
        } else {
            return ''
        }
    },

    formatDateTime(d: Date | null): string {
        if (d) {
            return (d.getDate()<10 ? '0': '') + d.getDate() + '.' + 
                (d.getMonth()+1 < 10 ? '0' : '') + 
                (d.getMonth()+1) + '.' + d.getFullYear() + ' ' + 
                (d.getHours()<10 ? '0': '') + d.getHours() + ':' + 
                (d.getMinutes()<10 ? '0' : '' + d.getMinutes())
        } else {
            return ''
        }
    },

    formatDateForInput(d: Date | null): string {
        if (d) {
            return d.getFullYear() + '-' + (d.getMonth()+1 < 10 ? '0' : '') + (d.getMonth()+1) + '-' + (d.getDate()<10 ? '0': '') + d.getDate()
        } else {
            return ''
        }
    },

    getDateNumber(d: Date): number {
        return  parseInt((d.getFullYear() + (d.getMonth()+1 < 10 ? '0' : '') + (d.getMonth()+1) + (d.getDate()<10 ? '0': '') + d.getDate()))
    },

    /* convertSecondsToTime(seconds:number) {
        let hours : any = Math.floor(seconds / 3600);
        let minutes : any = Math.floor((seconds - (hours * 3600)) / 60);
        let timestring = "";
        if (!isNaN(hours)) {
            if (hours < 10) { hours = "0" + hours; }
            timestring += hours + ':';
        }
        if (!isNaN(minutes)) {
            if (minutes < 10) { minutes = "0" + minutes; }
            timestring += minutes;
        }
        return (timestring ? timestring + " h" :  '00:00')
    }, */

    convertSecondsToTime(seconds:number/*, showSeconds = false*/) {
        let hours : any = Math.floor(seconds / 3600);
        let minutes : any = ((seconds - (hours * 3600)) / 60).toFixed(0);
            //due to rounding we could get 60 minutes
        if (minutes == 60) {
            minutes = 0
            hours += 1
        }
        let timestring = "";
        if (hours < 10) { hours = "0" + hours; }
            timestring += hours + ':';
        if (minutes < 10) { minutes = "0" + minutes; }
            timestring += minutes;
        /* if (showSeconds) {
            console.log('seconds, hours, minutes', seconds, hours*3600, minutes * 60);
            let displaySeconds : any = Math.floor((seconds - (hours * 3600) - (minutes * 60)) / 60);
            if (displaySeconds < 10) {displaySeconds = "0" + displaySeconds; }
            timestring += ':' + displaySeconds;
        } */
        return timestring;
    },

    convertTimeStampToDateAndTime(tstamp: number, addDayName: boolean) {
        return this.convertTimeStampToDate(tstamp, addDayName) + ' ' + this.convertTimeStampToTime(tstamp)
    },

    convertTimeStampToDateAndTimeWithSeconds(tstamp: number, addDayName: boolean) {
    
        const dateFormat = new Date(tstamp * 1000);
        let hours : any = dateFormat.getHours();
        let minutes : any = dateFormat.getMinutes();
        let seconds : any = dateFormat.getSeconds();

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }

        const secondsTime = hours + ":" + minutes + ':' + seconds;

        return this.convertTimeStampToDate(tstamp, addDayName) + ' ' + secondsTime
    },

    convertTimeStampToDate(tstamp:number, addDayName:boolean) {
        const date = new Date(tstamp * 1000);
        let datestring = "";
        if (addDayName) datestring += this.dayNames[date.getDay()] + ", "
        datestring += (date.getDate() < 10 ? "0" : "") + date.getDate() + "."
        datestring += ((date.getMonth() +1) < 10 ? "0" : "") + (date.getMonth() +1) + "."
        datestring += date.getFullYear()
        return datestring;
    },

    convertTimeStampToTime(tstamp:number){
        const dateFormat = new Date(tstamp * 1000);
        let hours : any = dateFormat.getHours();
        let minutes : any = dateFormat.getMinutes();

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        
        return hours + ":" + minutes;
    },

    convertToDate(datetime:string) {
        if (datetime) {
            if (datetime.split('T')[0]) {
                datetime = datetime.split('T')[0]
            }
            const date = new Date(datetime)
            const m = date.getMonth()+1
            return (date.getDate() < 10 ? '0' : '') + date.getDate() + '.' + (m < 10 ? '0' : '') + m + '.' + date.getFullYear()
        } else {
            return '-'
        }
    },

    convertLinuxDateStringToDate(datetime:string): Date | null {
        if (datetime) {
            if (datetime.split('T')[0]) {
                datetime = datetime.split('T')[0]
                const d = new Date(datetime)
                d.setHours(0,0,0,0)
                return d
            } else {
                return null
            }
        } else {
            return null
        }        
        //const m = date.getMonth()+1
        //return (date.getDate() < 10 ? '0' : '') + date.getDate() + '.' + (m < 10 ? '0' : '') + m + '.' + date.getFullYear()
    },

    convertLinuxDateStringToTimestamp(datetime:string): number {
        if (datetime) {
            if (datetime.split('T')[0]) {
                datetime = datetime.split('T')[0]
                return + new Date(datetime)
            } else {
                return 0
            }
            //const m = date.getMonth()+1
            //return (date.getDate() < 10 ? '0' : '') + date.getDate() + '.' + (m < 10 ? '0' : '') + m + '.' + date.getFullYear()
        } else {
            return 0
        }
    },

    /* extractDateOnly(datetime:string) {
        return datetime.split('T')[0]
    } */

};
export default DateTimeUtils;