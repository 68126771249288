import axios from "axios"
//import { Api } from "../api"
//import { CompanySettings } from '@/model'
import AxiosErrorService from "@/service/AxiosErrorService"

const NfcChipRepository = {
    response: null as any,
    chipId: '',

    async getUpdate(){
        try{
            const id = this.chipId
            if (this.chipId) this.chipId = ''
            //console.log('NfcChipRepository::getUpdate', Date.now(), this.chipId)
            const res = await axios.get('http://127.0.0.1:9999' + (id ? '?chip=' +  id : ''))
            this.response = res
            if (res?.data?.nfcChipId) this.chipId = res?.data?.nfcChipId
            //console.log('getUpdate res', res?.data?.nfcChipId)
            return res?.data?.nfcChipId
        } catch(e){
            console.log('getUpdate error:', e);
            this.response = e
            return 'FEHLER'
        }
    }

}

export default NfcChipRepository;