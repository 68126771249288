//import Store from "@/store";
//import { ViewController, StatementController, LinkController, ModelUserController, RatingController } from "@/controller/index.js";
import { WorkbreakController, PresenceController } from "./"
import { NfcChipRepository } from "../repository"

const UpdateController = {
    nfcChipId: '',
    nfcUpdateInterval: 2000,
    nfcUpdateTimeout: -1,
    updateInterval: 15000,
    updateTimeout: -1,
    employees: [] as Array<number>,

    nfcUpdateInProgress: false,
    updateInProgress: false,
    lastNfcUpdateFetched: 0,
    lastUpdateFetched: 0,
    nfcUpdateStopped: false,
    updateStopped: false,
    route: {} as any,
    nfcResponse: null as any,

        //callbacks
    beforeCheckNfcUpdate() { /* set this */ },
    beforeCheckUpdate() { /* set this */ },
    onNfcUpdate(nfcChipId: string) { /* quick and dirty */ console.log('onNfcUpdate', nfcChipId) },
    onUpdate(workbreaks: Array<any>, presences: Array<any>) { /* quick and dirty */ console.log('onUpdate') },
    afterCheckNfcUpdate() { /* for workaround ts compiler */ },
    afterCheckUpdate() { /* for workaround ts compiler */ },

    getNfcUpdateInProgress() {
        return this.nfcUpdateInProgress;
    },

    setNfcUpdateInProgress(nfcUpdateInProgress: boolean) {
        this.nfcUpdateInProgress = nfcUpdateInProgress;
    },

    getUpdateInProgress() {
        return this.updateInProgress;
    },

    setUpdateInProgress(updateInProgress: boolean) {
        this.updateInProgress = updateInProgress;
    },

    getLastNfcUpdateFetched() {
        return this.lastNfcUpdateFetched;
    },

    setLastNfcUpdateFetched(updateFetched: number) {
        this.lastNfcUpdateFetched = updateFetched;
    },

    getLastUpdateFetched() {
        return this.lastUpdateFetched;
    },

    setLastUpdateFetched(updateFetched: number) {
        this.lastUpdateFetched = updateFetched;
    },

    getRoute() {
        return this.route;
    },

    setRoute(route:any) {
        this.route = route;
        console.log("UpdateController:setRoute:route", this.route);
    },

    getBeforeCheckNfcUpdate() {
        return this.beforeCheckNfcUpdate;
    },

    setBeforeCheckNfcUpdate(beforeCheckNfcUpdate: any) {
        this.beforeCheckNfcUpdate = beforeCheckNfcUpdate;
    },

    getBeforeCheckUpdate() {
        return this.beforeCheckUpdate;
    },

    setBeforeCheckUpdate(beforeCheckUpdate: any) {
        this.beforeCheckUpdate = beforeCheckUpdate;
    },

    getOnNfcUpdate() {
        return this.onNfcUpdate;
    },

    setOnNfcUpdate(onNfcUpdate: any) {
        this.onNfcUpdate = onNfcUpdate;
    },

    getOnUpdate() {
        return this.onUpdate;
    },

    setOnUpdate(onUpdate: any) {
        this.onUpdate = onUpdate;
    },

    getAfterCheckNfcUpdate() {
        return this.afterCheckNfcUpdate;
    },

    setAfterCheckNfcUpdate(afterCheckNfcUpdate: any) {
        this.afterCheckNfcUpdate = afterCheckNfcUpdate;
    },    

    getAfterCheckpdate() {
        return this.afterCheckUpdate;
    },

    setAfterCheckUpdate(afterCheckUpdate: any) {
        this.afterCheckUpdate = afterCheckUpdate;
    },    

    nfcUpdate() {
        window.clearTimeout(UpdateController.nfcUpdateTimeout);
        UpdateController.nfcUpdateTimeout = window.setTimeout(() => {
            if (!UpdateController.getNfcUpdateInProgress()) UpdateController.checkNfcUpdate();
            if (!this.nfcUpdateStopped) UpdateController.nfcUpdate();
        }, UpdateController.nfcUpdateInterval);
    },

    update() {
        window.clearTimeout(UpdateController.updateTimeout);
        UpdateController.updateTimeout = window.setTimeout(() => {  
            UpdateController.checkUpdate();
            if (!this.updateStopped) UpdateController.update();
        }, UpdateController.updateInterval);
    },

    startNfcUpdate() {
        this.nfcUpdateStopped = false;
        this.nfcUpdate();
    },

    startUpdate() {
        this.updateStopped = false;
        this.update();
    },

    stopNfcUpdate() {
        this.nfcUpdateStopped = true;
        window.clearTimeout(UpdateController.nfcUpdateTimeout);
    },

    stopUpdate() {
        this.updateStopped = true;
        window.clearTimeout(UpdateController.updateTimeout);
    },

    async checkNfcUpdate() {
        //console.log("UpdateController:this.route", this.route);
        if (this.route.fullPath.indexOf('/workspace') != -1) await this.fetchNfcChipUpdate();
        //if (this.route.name === "Project") await this.fetchModelUpdates();
        //if (this.route.name === "Rating" && this.ratingId) await this.fetchRatingUpdates();
        //if (this.route.name === "Preferencing" && this.preferencingId) await this.fetchPreferencingUpdates();
    },

    async checkUpdate() {
        //console.log("UpdateController:udpate this.route", this.route);
        if (this.route.fullPath.indexOf('/overview') != -1 || this.route.fullPath.indexOf('/workspace') != -1) await this.fetchEmployeeUpdate();
        //if (this.route.name === "Project") await this.fetchModelUpdates();
        //if (this.route.name === "Rating" && this.ratingId) await this.fetchRatingUpdates();
        //if (this.route.name === "Preferencing" && this.preferencingId) await this.fetchPreferencingUpdates();
    },

    async fetchNfcChipUpdate() {
        //console.log('fetchNfcChipUpdate', UpdateController.getNfcUpdateInProgress())

        if (UpdateController.getNfcUpdateInProgress()) {
            return;
        }

        if (this.beforeCheckNfcUpdate && typeof(this.beforeCheckNfcUpdate) != 'undefined' && typeof this.beforeCheckNfcUpdate === "function") {
            this.beforeCheckNfcUpdate();
        }

        UpdateController.setNfcUpdateInProgress(true)

        //console.log('UpdateController::fetchNfcChipUpdate', Date.now(), this.nfcChipId)
        const id = await NfcChipRepository.getUpdate()
        this.nfcResponse = NfcChipRepository.response

        //console.log('this.nfcCihpId', this.nfcChipId)
        //if (this.nfcChipId) this.onNfcUpdate(this.nfcChipId)
        this.nfcChipId = id
        if (this.nfcChipId) this.stopNfcUpdate()
        this.onNfcUpdate(id)

        //if (response && response.data.success) {
            //console.log("UpdateController:fetchNfcChipUpdate:response", response);

            //this.checkPreferencingUpdates(response);
           // UpdateController.setLastNfcUpdateFetched(response.data.tstamp);
        //}

        UpdateController.setNfcUpdateInProgress(false);

        if (this.afterCheckNfcUpdate && typeof this.afterCheckNfcUpdate === "function") {
            this.afterCheckNfcUpdate();
        }

    },

    async fetchEmployeeUpdate() {
        //console.log('UpdateController:fetchEmployeeUpdate')
        if (UpdateController.getUpdateInProgress()) {
            return;
        }

        if (this.beforeCheckUpdate && typeof(this.beforeCheckUpdate) != 'undefined' && typeof this.beforeCheckUpdate === "function") {
            this.beforeCheckUpdate();
        }

        UpdateController.setUpdateInProgress(true)
            //TODO update employees, but this only makes sense if we have the updated timestamp set

        console.log('UpdateController::fetchEmployeeUpdate', this.employees)
        const workbreaks = await WorkbreakController.getActiveWorkbreak(this.employees)
        const presences = await PresenceController.getActivePresence(this.employees)
        //console.log('UpdateController::workbreaks', workbreaks, presences)
        this.onUpdate(workbreaks, presences)

        //this.nfcChipId = await NfcChipRepository.getUpdate()        
        //if (this.nfcChipId) this.onNfcUpdate(this.nfcChipId)

        //if (response && response.data.success) {
            //console.log("UpdateController:fetchNfcChipUpdate:response", response);

            //this.checkPreferencingUpdates(response);
           // UpdateController.setLastNfcUpdateFetched(response.data.tstamp);
        //}

        UpdateController.setUpdateInProgress(false);

        if (this.afterCheckUpdate && typeof this.afterCheckUpdate === "function") {
            this.afterCheckUpdate();
        }

    }
}

//UpdateController.startUpdate();

export default UpdateController;
