import router from "./router";
import { SessionController, RightController, UpdateController, WorkstationController } from "@/controller";
//import { setLoading, unsetLoading } from "@/utils/loading";
    //TODO: check if '/' is needed in allowed routes
//const allowedRoutes = ['/', '/forgotpassword', '/resetpassword', '/login']
const allowedRoutes = ['/forgotpassword', '/resetpassword', '/login']

router.beforeEach(async (to, from, next) => {
    SessionController.loginRequired = true
    const allow = allowedRoutes.filter((route) => to.path == route || (route != '/' && to.path.indexOf(route) != -1) );
    if (allow?.length || to?.meta?.noLoginCheck === true) {
        SessionController.loginRequired = false
        next()
      } else {
        //console.log('permission.ts')
        if (!SessionController.sessionUser.uid) {
            await SessionController.getSessionUser()
            //console.log('permission getSessionUser', SessionController.sessionUser.uid)
        }
        if (!SessionController.sessionCompany.uid) {
            await SessionController.getSessionCompany()
            //console.log('permission getCompany', SessionController.sessionCompany.uid)
        }
        if (SessionController.sessionUser.uid && SessionController.sessionCompany.uid) {
            const isAdmin = RightController.checkAdmin()
            if (!isAdmin && to.path.includes('/administration')) {
                next({ path: '/' })
            } else if (!SessionController.sessionEmployee.uid && to.fullPath.indexOf('/workspace') == -1) {
                next({ path: '/workspace' })
            } else {
                if (!SessionController.sessionEmployee.uid && !WorkstationController.getLocalStorageUuidWorkstation() && to.fullPath.indexOf('/setchip') == -1) {
                    console.log('must set workstation')
                    next({ path: '/workspace/setchip' })
                } else {
                    next()
                }
            }
        }
    }
    UpdateController.setRoute(to)
    if (to.fullPath.indexOf('/workspace') != -1) UpdateController.startNfcUpdate()
    if (to.fullPath.indexOf('/overview') != -1 || to.fullPath.indexOf('/workspace') != -1) UpdateController.startUpdate()
})
